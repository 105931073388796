<template>
  <div class="content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @submit.native.prevent @keyup.enter.native="handleSearch">
            <el-form-item label="标签名称">
              <el-input v-model="name" placeholder="请输入标签名称搜索" clearable  @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="showAddDialog" v-prevent-click>新增</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table size="small" :data="tableData">
        <el-table-column prop="Name" label="标签名称"></el-table-column>
        <el-table-column label="操作" width="145px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="showEditDialog(scope.row)"
              v-prevent-click
            >编辑</el-button>
            <el-button
                     v-if="isDelete"
              type="danger"
              size="small"
              @click="removeTag(scope.row)"
              v-prevent-click
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page pad_10">
        <div class="text_right" v-if="paginations.total > 0">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page.sync="paginations.page"
            :page-size="paginations.page_size"
            :layout="paginations.layout"
            :total="paginations.total"
          ></el-pagination>
        </div>
      </div>
    </div>

    <!--新增弹窗-->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" size="small">
          <el-form-item label="标签名称" prop="Name">
            <el-input size="small" width="60%" v-model="ruleForm.Name"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false" v-prevent-click>取 消</el-button>
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="toggle"
        >保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/CRM/Customer/customerTagLibrary";
import permission from "@/components/js/permission.js";

export default {
  name: "CustomerTagLibrary",
  data() {
    return {
      loading: false,
      modalLoading: false,
      dialogVisible: false,
        isDelete:false,
      dialogTitle: "",
      tableData: [],
      tagList: [],
      switchId: 0,
      name: "",
      ruleForm: {
        ID: "",
        Name: ""
      },
      rules: {
        Name: [{ required: true, message: "请输入标签名称", trigger: "change" }]
      },
      //需要给分页组件传的信息
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper" // 翻页属性
      }
    };
  },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.isDelete = permission.permission(to.meta.Permission,"CRM-Customer-CustomerTagLibrary-Delete")
        })
    },
  methods: {
    // 数据显示
    handleSearch: function() {
      let that = this;
      that.paginations.page = 1;
      that.search();
    },
    // 数据显示
    search: function() {
      let that = this;
      that.loading = true;
      var params = {
        Name: that.name,
        PageNum: that.paginations.page
      };
      API.customerTagLibraryList(params)
        .then(res => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.search();
    },
    // 新增弹窗
    showAddDialog: function() {
      var that = this;
      that.switchId = 1;
      that.ruleForm = {
        ID: "",
        Name: ""
      };
      that.dialogTitle = "新增会员标签";
      that.dialogVisible = true;
    },
    //新增
    createTag() {
      let that = this;
      that.$refs.ruleForm.validate(valid => {
        if (valid) {
          var params = {
            Name: that.ruleForm.Name
          };
          that.modalLoading = true;
          API.customerTagLibraryCreate(params)
            .then(res => {
              if (res.StateCode == 200) {
                that.dialogVisible = false;
                that.$message.success({
                  message: res.Message,
                  duration: 2000
                });
                that.search();
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000
                });
              }
            })
            .finally(function() {
              that.modalLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 编辑弹窗
    showEditDialog: function(row) {
      var that = this;
      that.switchId = 2;
      that.ruleForm = Object.assign({}, row);
      that.dialogVisible = true;
      that.dialogTitle = "编辑会员标签";
    },
    //编辑保存
    saveTag() {
      let that = this;
      that.$refs.ruleForm.validate(valid => {
        if (valid) {
          that.modalLoading = true;
          var params = {
            ID: that.ruleForm.ID,
            Name: that.ruleForm.Name
          };
          API.customerTagLibraryUpdate(params)
            .then(res => {
              if (res.StateCode == 200) {                
                that.dialogVisible = false;
                that.$message.success({
                  message: res.Message,
                  duration: 2000
                });
                that.search();
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000
                });
              }
            })
            .finally(function() {
              that.modalLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    //根据switchId判断是新增还是编辑保存
    toggle() {
      var that = this;
      if (that.switchId == 1) {
        that.createTag();
      } else {
        that.saveTag();
      }
    },
    // 删除
    removeTag: function(row) {
      var that = this;
      that
        .$confirm("此操作将永久删除该记录, 是否继续?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          that.modalLoading = false;
          var params = {
            ID: row.ID
          };
          API.customerTagLibraryDelete(params)
            .then(res => {
              if (res.StateCode == 200) {
                that.$message.success("删除成功");
                that.search();
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000
                });
              }
            })
            .finally(function() {
              that.modalLoading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    }
  },
  mounted() {
    var that = this;
    that.isDelete = permission.permission(that.$route.meta.Permission,"CRM-Customer-CustomerTagLibrary-Delete")
    that.handleSearch();
  }
};
</script>

<style lang="scss">
.el-input__inner {
  padding-right: 0;
}

.el-table .info-row {
  background: #c0c4cc;
}
</style>
